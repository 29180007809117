<template>
  <main class="main">
    <section class="contacts-description">
      <BreadcrumbsComponent :links="breadcrumbs" />
      <h2 class="contacts-description__title">Контакты</h2>
      <h1 style="display: none">{{ meta.meta_title ?? "Контакты" }}</h1>
    </section>
    <ContactsInfoComponent />
  </main>
</template>

<script>
import ContactsInfoComponent from "./components/ContactsInfoComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";

export default {
  name: "ContactsPage",
  data: () => ({
    breadcrumbs: [
      {
        route: {
          name: "contacts",
        },
        title: "Контакты",
      },
    ],
  }),
  components: { BreadcrumbsComponent, ContactsInfoComponent },
  computed: {
    meta() {
      return this.$store.state.contacts_page.meta;
    },
  },
  metaInfo() {
    const itemListElement = this.breadcrumbs.map((crumb, index) => ({
      "@type": "ListItem",
      position: index + 2,
      item: {
        "@id": "https://comrades.dev/" + crumb.route.name,
        name: crumb.title,
      },
    }));

    itemListElement.unshift({
      itemListElement: {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://comrades.dev",
          name: "Главная",
        },
      },
    });

    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: itemListElement,
    };

    return {
      title: this.$store.state.contacts_page.meta.meta_title ?? "Контакты",
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$store.state.contacts_page.meta.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: this.$store.state.contacts_page.meta.meta_description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: this.$store.state.contacts_page.meta.meta_title ?? "Контакты",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: this.$store.state.contacts_page.meta.meta_description,
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: jsonLd,
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.contacts-description {
  display: flex;
  flex-direction: column;
  padding: 32px 100px 60px;
  gap 60px
  background: var(--gray-1);
  width: 100%;
  +below(1400px) {
    padding: 32px 80px 15px;
  }
  +below(1100px) {
    padding: 32px 15px 15px;
  }

  &__title {
    font-weight: 500;
    font-size: 3.75rem;
    line-height: 71px;
    color: var(--blue);
    margin 0
    padding 0

    +below(1100px) {
      font-size: 1.875rem;
      line-height: 35px;
    }
  }
}
</style>
